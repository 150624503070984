import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const routeConfig = {
  '/login': '登录',
  '/home': '首页',
  '/': '首页',
}
export default function RouteChangeListener() {
  
  const location = useLocation()
  useEffect(() => {
    const pageTitle = routeConfig[location.pathname]
    // 完整路径；必须含有http协议和域名，否则识别为空；
    const hostname = window.location.protocol+"//"+window.location.host+"/#" + location.pathname;

    window._yfx_trackdata = window._yfx_trackdata || []; // 变量声明
    window._yfx_trackdata.push(['pageview', hostname, pageTitle]);//页面切换采集；to.name为页面标题，也可以自定义标题

    // console.log("当前路径:", pageTitle, hostname);
  }, [location]);
  
  return null
};
