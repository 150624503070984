import moment from "moment";

const VALID_TAGS = ['siInvitation060', 'siInvitation070'];
const getMonday = (date) => {
  const dateIns = date ? moment(date,'YYYY-MM-DD') : moment()
  var weekOfDay = dateIns.format('E');//计算指定日期是这周第几天
  var monday = dateIns.clone().subtract(weekOfDay - 1, 'days').format('YYYYMMDD');//周一日期
  // var sunday = dateIns.clone().add(7 - weekOfDay, 'days').format('YYYYMMDD');//周日日期
  // console.log('本周时间范围：', dateIns.format('YYYY-MM-DD'), weekOfDay, monday, sunday)
  return monday
}
export const trackUser = (referrer = '') => {
  try {
    const tags = referrer?.split(';') ?? [];
    const idx = tags.findIndex((i) => VALID_TAGS.includes(i));
    if (idx === -1) {
      return;
    }
    const tagTime = getMonday()
    window._yfx_trackdata = window._yfx_trackdata || [];
    window._yfx_trackdata.push(['userset', { tag: `${tags[idx]}-${tagTime}` }]);
    
    // console.log('🚀 ~ trackUser ~ data:', `${tags[idx]}-${tagTime}`);
  } catch (e) {
    console.log(e);
  }
};

export const trackEvent = (eventCate, eventName, data = {}) => {
  try {
    window._yfx_trackdata = window._yfx_trackdata || [];
    window._yfx_trackdata.push(['event', eventCate, eventName, data]);
  } catch (e) {
    console.log(e);
  }
};