import ProForm, { ProFormText } from "@ant-design/pro-form"
import { Button, Image, message } from "antd"
import { useCallback, useEffect, useLayoutEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import WRAPPER_IMG from "../assets/images/login_bg.jpg"
import LOGO_IMG from "../assets/images/logo_fff.png"
import { IconFont } from "../components/common/IconFont"
import { LocaleChange } from "../components/common/LocaleChange"
import strings from "../constants/strings"
import apis from "../services/apis"
import { post } from "../services/request"
import {  setStorage } from "../utils/storageHelper"
import { getQueryString } from "../utils/util"
import ICON_PC from "../assets/images/icon_pc.png"
import ICON_MOBILE from "../assets/images/icon_mobile.png"
import config from "../config/config"
import postMessageType from "../constants/postMessageType"
import { trackUser } from "../utils/yeefx"

// const { shell } = require("electron")

const { hotSignHost, hotSignResourceHost } = config
const DOWNLOAD_URL = `${hotSignResourceHost}/mobile/appdownload`
const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${WRAPPER_IMG}) center center / auto 100% no-repeat;

  .left-x {
    max-width: 30%;
    font-weight: 500;
    text-align: center;
    color: rgba(255 255 255 / 80%);
    .greeting {
      margin: 16px 0;
      font-size: 28px;
    }
    .donwload-tip {
      margin: 16px 0;
      font-size: 16px;
    }
    .btn-download {
      padding: 4px 12px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      box-shadow: unset;
      border: 0;
      background-color: #6785de;
      border-radius: 4px;
      cursor: pointer;
      font-weight: inherit;
      color: #ffffff;
      opacity: 0.9;
      &.mobile {
        margin: 0 auto;
      }
      .icon-pc,
      .icon-mobile {
        width: 20px;
        margin-right: 4px;
      }
    }
    .electron-ul {
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-download {
        margin-top: 8px;
      }
      .btn-download + .btn-download {
        margin-left: 8px;
      }
    }
  }
  .logo-x {
    width: 600px;
    height: 480px;
    padding: 32px 32px 56px;
    margin-left: 48px;
    background-color: rgba(250, 250, 250, 0.6);
    border-radius: 4px;
    text-align: center;
    color: #333333;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 36px;
      font-weight: 500;
    }
    .form-login-x {
      margin-top: 24px;
      flex: 1;
      .ant-form-item-label > label {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .ant-input-affix-wrapper {
        border: 0;
        border-radius: 4px;
        &:hover,
        &:focus,
        &-focused {
          border-color: #6785de;
          box-shadow: none;
        }
      }
      .ant-input {
        height: 40px;
        margin-left: 8px;
        background-color: rgba(255, 255, 255, 0.85);
        font-size: 14px;
        color: #666666;
        &::placeholder {
          color: #999999;
        }
      }
      .icon-user,
      .icon-clear,
      .icon-password {
        width: 14px;
        height: 14px;
        fill: #666666;
      }
      .ant-space {
        margin-top: 24px;
        gap: 24px !important;
        .ant-btn {
          width: 80px;
          height: 32px;
          // cancel
          color: #666666;
          border: 1px solid #999999;
          background-color: transparent;
          &:hover {
            color: #6785de;
            border-color: currentColor;
          }
        }
        .ant-btn-primary {
          // ok
          border-color: #6785de;
          color: white;
          background-color: #6785de;
          &:hover {
            color: white;
            border-color: #6785de;
          }
        }
      }
      .none-account-tip {
        text-align: left;
        color: #333333;
        & > a {
          margin-left: 8px;
          font-weight: 500;
          color: #6785de;
        }
      }
    }
  }
  /* 语言切换按钮 */
  .btn-locale {
    position: fixed;
    top: 24px;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-locale {
      width: 16px;
      height: 16px;
      fill: white;
    }
    .name {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 500;
      color: white;
    }
  }
`

const LoginPage = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const isElectron =
    navigator.userAgent.toLowerCase().indexOf(" electron/") > -1
  const loginCB = useCallback(async data => {
    const entities = {}
    data.map(item => {
      entities[item.account] = item
      return item
    })
    setStorage(strings.storageKeyAccInfo, {
      ids: data.map(item => item.account),
      entities,
    })
    setStorage(strings.storageKeyCurrAcc, data[0].account)
    setTimeout(() => {
      navigate("/")
    }, 0)
  }, [navigate])
  const handleLogin = async values => {
    for (let value of Object.values(values)) {
      if (!value) {
        message.error(
          intl.formatMessage({ id: "login.form.message.error.valueInvalid" })
        )
        return
      }
    }
    const { error, data } = await post(apis.loginOA2, {
      ...values,
      device: "pc",
    })
    if (error) {
      message.error(
        error ||
          intl.formatMessage({ id: "login.form.message.error.valueInvalid" })
      )
      return
    }
    message.success(
      intl.formatMessage({ id: "login.form.message.success.signIn" })
    )
    loginCB(data)
  }
  // const handleAutoLogin = async () => {
  //   const token = getQueryString("t")
  //   const thirdPartProjectName = getQueryString("s")
  //   if (thirdPartProjectName) {
  //     setStorage(strings.storageKeyThirdPartProjectName, thirdPartProjectName)
  //   }
  //   if (token) {
  //     const { error, data } = await post(apis.loginOA1, { token })
  //     if (error) {
  //       message.error(
  //         intl.formatMessage({ id: "login.form.message.error.autoSignIn" })
  //       )
  //       return Promise.reject(error)
  //     }
  //     message.success(
  //       intl.formatMessage({ id: "login.form.message.success.autoSignIn" })
  //     )
  //     await loginCB(data)
  //   }
  //   return Promise.resolve()
  // }
  
  // NOTE: 打开 火象 App 下载页（网页内直接新开标签页打开，electron 应用里复制到剪贴板）
  const openDownloadUrl = () => {
    if (isElectron) {
      // Electron-specific code
      navigator.clipboard.writeText(DOWNLOAD_URL).then(() => {
        message.success(
          intl.formatMessage({ id: "login.message.success.copyDownloadUrl" })
        )
      })
    } else {
      window.open(DOWNLOAD_URL)
    }
  }

  const postMessageToParent = (type, data) => {
    if (!window.parent) return
    window.parent.postMessage(JSON.stringify({type, data}), hotSignHost)
  }
  useLayoutEffect(() => {
    const thirdPartProjectName = getQueryString("s")
    if (thirdPartProjectName) {
      setStorage(strings.storageKeyThirdPartProjectName, thirdPartProjectName)
    }
    const needAutoLogin = !isElectron && !!thirdPartProjectName
    if (!needAutoLogin) return

    // 第三方自动登录，向第三方申请 token
    // console.log('1. wt 向 pro 申请 token')
    postMessageToParent(postMessageType.fetchToken)
  }, [isElectron])

  useEffect(() => {
    const handleAutoLogin = async (token) => {
      const { error, data } = await post(apis.loginOA1, { token })
      if (error) {
        message.error(
          intl.formatMessage({ id: "login.form.message.error.autoSignIn" })
        )
        return { error }
      }
      message.success(
        intl.formatMessage({ id: "login.form.message.success.autoSignIn" })
      )
      await loginCB(data)
      return { error: null }
    }
    const handleMessage = async (event) => {
      try {
        const { origin, data: originData } = event
        if (origin !== hotSignHost) return

        const { type, data } = JSON.parse(originData)

        switch (type) {
          case postMessageType.fetchToken: {  // 收到三方传递过来的登录令牌
            if (data?.token) {
              // console.log('3. 收到 pro 发回的 token')
              const { error } = await handleAutoLogin(data.token)
              // console.log(`4. wt 自动登录完成，登录结果为：${!error}，错误信息：${error}`)
              postMessageToParent(postMessageType.autoLogin, { isCompleted: !error })
            }
            break
          }
          case postMessageType.fetchReferrer: {
            // console.log('3. 收到 pro 发回的 referrer')
            // TODO: Yeefx 给用户打标签
            trackUser(data?.referrer)
            break
          }
          default:
            break
        }
      } catch (error) {
        console.log("[iframe trader] Something went wrong", error)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [intl, loginCB])

  // useEffect(() => {
  //   try {
  //     clearStorage()
  //     const thirdPartProjectName = getQueryString("s")
  //     const needAutoLogin = !isElectron && thirdPartProjectName
  //     if (needAutoLogin) {
  //       handleAutoLogin()
  //         .then(() => {
  //           if (window.parent) {
  //             console.log("==> auto login then")
  //             window.parent.postMessage(
  //               { error: null, data: { loading: false } },
  //               hotSignHost
  //               // "http://localhost:3000"
  //             )
  //           }
  //         })
  //         .catch(error => {
  //           console.log("==> auto login catch", error)
  //           if (window.parent) {
  //             window.parent.postMessage(
  //               { error, data: { loading: true } },
  //               hotSignHost
  //               // "http://localhost:3000"
  //             )
  //           }
  //         })
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  return (
    <Wrapper>
      <div className="left-x">
        <img src={LOGO_IMG} alt="ALPHA" width={320} />
        <p className="greeting">
          <FormattedMessage id="login.left.title" />
        </p>
        <p className="donwload-tip">
          <FormattedMessage id="login.left.desc" />
        </p>
        <Button
          className="btn-download mobile"
          onClick={() =>
            window.open("https://www.huosign.com/mobile/appdownload")
          }
        >
          <Image className="icon-mobile" src={ICON_MOBILE} preview={false} />
          <FormattedMessage id="login.left.downloadBtnText" />
        </Button>
        {!isElectron && (
          <>
            <p className="donwload-tip">
              <FormattedMessage id="login.left.electron.tip" />
            </p>
            <div className="electron-ul">
              <Button
                className="btn-download"
                onClick={() =>
                  window.open(
                    "https://webtrader.oss-cn-shanghai.aliyuncs.com/WebTrader-1.0.0.dmg"
                  )
                }
              >
                <Image className="icon-pc" src={ICON_PC} preview={false} />
                <FormattedMessage id="login.left.electron.mac" />
              </Button>
              <Button
                className="btn-download"
                onClick={() =>
                  window.open(
                    "https://webtrader.oss-cn-shanghai.aliyuncs.com/WebTrader%20Setup%201.0.0.exe"
                  )
                }
              >
                <Image className="icon-pc" src={ICON_PC} preview={false} />
                <FormattedMessage id="login.left.electron.win" />
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="logo-x">
        <p className="title">
          <FormattedMessage id="login.form.title" />
        </p>
        <ProForm
          className="form-login-x"
          onFinish={handleLogin}
          submitter={{
            // 配置按钮文本
            searchConfig: {
              submitText: <FormattedMessage id="login.form.btns.submit" />,
              resetText: <FormattedMessage id="login.form.btns.reset" />,
            },
          }}
          requiredMark
        >
          <ProFormText
            name="username"
            label={<FormattedMessage id="login.form.userName.label" />}
            placeholder={intl.formatMessage({
              id: "login.form.userName.placeholder",
            })}
            fieldProps={{
              prefix: <IconFont className="icon-user" />,
            }}
          />
          <ProFormText.Password
            name="password"
            label={<FormattedMessage id="login.form.pwd.label" />}
            placeholder={intl.formatMessage({
              id: "login.form.pwd.placeholder",
            })}
            fieldProps={{
              prefix: <IconFont className="icon-password" />,
            }}
          />
          <p className="none-account-tip">
            <FormattedMessage
              id="login.form.createAccount"
              values={{
                a: txt => (
                  <a
                    target="_blank"
                    href="https://www.alphazone.com.cn/register.html"
                    rel="noreferrer"
                  >
                    {txt}
                  </a>
                ),
              }}
            />
          </p>
        </ProForm>
      </div>
      <LocaleChange />
    </Wrapper>
  )
}

export default LoginPage
