// import { message } from "antd"
import { addZeroInFront, convertResolution } from "../../utils/util"
import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js"
// import { getSymbolDataListFromStore } from "../../../stores/useSymbolStore"
import { store } from "../../store/store"
import { post } from "../../services/request"
import apis from "../../services/apis"
import { send, subscribeOnce } from "../../store/socketActions.js"
import { setStorage } from "../../utils/storageHelper.js"
import strings from "../../constants/strings.js"

const { dispatch, getState } = store

const CONFIG_OPTS = {
  supports_search: true,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  supported_resolutions: ["1", "5", "15", "30", "60", "240", "1D"], // 支持的周期数组，周期可以是数字或字符串。 如果周期是一个数字，它被视为分钟数
  // supports_marks: false, // datafeed 是否支持在K线上显示标记
  // supports_timescale_marks: false, // 是否支持时间刻度标记。
  // supports_time: false, // 将此设置为true假如您的datafeed提供服务器时间（unix时间）。 它仅用于在价格刻度上显示倒计时
}
const SYMBOLINFO_OPTS = {
  minmov2: 0, // 不同种类价格最小波动参数，常见为0，ZFM2014五年期国债为4
  has_intraday: true, // 布尔值显示商品是否具有日内（分钟）历史数据。
  intraday_multipliers: ["1", "5", "15", "30", "60", "240", "1D"], // 当前商品支持的周期，只有被设置了的周期才会触发图表库的请求
  visible_plots_set: "ohlcv", // 表示商品拥有的数据项（open/high/low/close/volume）
  // has_weekly_and_monthly: false,
}

// 根据 symbol_info 接口返回的 session 字段计算图表库需要的交易时段值
// const getSessionForChart = session => {
//   let newSession = []
//   session.map(item => {
//     const { trade, week } = item
//     let sessionDay = []
//     {
//       !!Array.isArray(trade) &&
//         trade.map(item => {
//           let {
//             open_hour: openHour,
//             open_min: openMin,
//             close_hour: closeHour,
//             close_min: closeMin,
//           } = item
//           sessionDay.push(
//             `${addZeroInFront(openHour)}${addZeroInFront(
//               openMin
//             )}-${addZeroInFront(closeHour)}${addZeroInFront(closeMin)}`
//           )
//         })
//     }
//     // TEMP:
//     // sessionDay.splice(sessionDay.length - 1, 1)
//     // sessionDay.push("0000-2400")

//     sessionDay.length && newSession.push(`${sessionDay.join(",")}:${week + 1}`)
//   })
//   // console.log(newSession.join("|"))
//   return newSession.join("|")
// }

export default class Datafeed1 {
  // #wsIns = null
  hasHistoryData = true
  storeSubscribers = []

  // constructor() {
  // console.log("[constructor]: Method call")
  // this.#wsIns = getWebSocketInstanceFromStore()
  // }
  /**
   * 设置图表库支持的图表配置
   * @param {Function} callback function(configurationData)
   *    configurationData {Object}
   * 使用 回调函数 来传递 datafeed 的 configurationData参数
   */
  onReady = callback => {
    new Promise(resolve => {
      resolve(CONFIG_OPTS)
    }).then(data => {
      callback(data)
    })
  }

  // 搜索商品并展示
  searchSymbols = async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const symbolsFromWS = getState().symbols?.entities
    const symbols = []
    Object.values(symbolsFromWS).forEach(item => {
      if (item.name.toUpperCase().indexOf(userInput.toUpperCase()) !== -1) {
        symbols.push({
          symbol: item.name,
          full_name: item.name, // e.g. BTCE:BTCUSD
          description: item.description ?? item.cn_name,
          exchange: "",
          ticker: item.symbol,
          type: "",
        })
      }
    })
    onResultReadyCallback(symbols)
  }

  /**
   * 通过商品名称解析商品信息（图表库会在需要由传入的 交易品种名称 获取 SymbolInfo 时调用此函数）
   * @param {String} symbolName 交易品种名称或者已提供的 ticker
   * @param {Function} onSymbolResolvedCallback function(SymbolInfo)
   * @param {Function} onResolveErrorCallback function(reason)
   */
  resolveSymbol = async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    try {
      // console.log("[resolveSymbol]: Method call")
      // 获取商品详细信息
      // const { error, data } = await post(apis.getSymbolInfo, {
      //   name: symbolName,
      // })
      const { error, data } = await post(apis.getSymbolInfo, {
        name: symbolName,
      })
      if (error) throw error
      const { symbol, description, sessions, volume_step, digits } = data
      const symbolInfo = {
        ...SYMBOLINFO_OPTS,
        name: symbol,
        ticker: symbol,
        description,
        timezone: "Asia/Shanghai",
        session: "24x7", // NOTE: 按理说应该设置成下面的值，但接口数据没有按照对应session返回，故只能这样设置才能保证Bar的数据没有错误
        // session: getSessionForChart(sessions), // 根据接口数据按照 TradingView 要求格式处理（NOTE: 这个参数会直接影响K线的加载，也会在图表的开市信息中展示出来）
        // minmov: volume_step * 100, // 价格步长：1 表示 0.01
        minmov: 1,
        pricescale: Math.pow(10, digits), // 小数位数：1000 表示 0.001
      }
      setTimeout(() => {
        onSymbolResolvedCallback(symbolInfo)
      }, 0)
    } catch (e) {
      onResolveErrorCallback(e || e.message || "解析商品信息失败")
    }
  }

  /**
   * 当图表库需要由日期范围定义的历史K线片段时，将调用此函数
   * @param {Object} symbolInfo 商品信息对象
   * @param {*} resolution 商品交易周期
   * @param {*} periodParams 具有以下字段的对象：
   * 1. from - unix 时间戳, 最左边请求的K线时间(K线时间 >= from)，单位为 秒（包含此时间）
   * 2. countBack - 要加载的K线的确切数量，如果您的 datafeed 支持它，则将视为拥有比 from 更高的优先级。 如果用户请求特定时间段，则可以不指定。
   * 3. to: unix 时间戳, 最右边请求的K线时间(K线时间 < to)（不包含此时间）
   * 4. firstDataRequest: 布尔值，以标识是否第一次调用此商品/周期的历史记录。当设置为true时 你可以忽略to 参数（这取决于浏览器的 Date.now() ) 并返回K线数组直到最新K线。
   * @param {*} onHistoryCallback 历史数据的回调函数。每次请求只应被调用一次。 此函数有2个参数：
   * 1. bars: Bar对象数组 {time, close, open, high, low, volume}[]
   * 2. meta: object {noData = true | false, nextTime = unix time}
   * @param {*} onErrorCallback 错误的回调函数。 此函数的唯一参数是文本错误消息。
   */
  getBars = (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    // console.log("[getBars]: Method call", periodParams)

    // NOTE: 在每一次获取K线历史记录时更新当前的历史记录，页面下一次刷新时采用这个K线的商品信息初始化图表
    setStorage(
      strings.storageKeyHistoryKlineInfo,
      {
        symbol: symbolInfo.name,
        interval: resolution,
      },
      1
    )

    // 主流程：订阅商品当前时间段的历史数据
    // console.log("[getBars]: Method call")
    // if (this.onLoadingHistoryData) {
    //   console.log("[getBars]: on loading data, Method return")
    //   return
    // }
    // this.onLoadingHistoryData = true
    const { name } = symbolInfo
    const { to, countBack, firstDataRequest } = periodParams
    setTimeout(() => {
      try {
        // NOTE: 必须将 send/subscribe 放到延迟里，否则多条信息快速 send 时，会收不到服务端对应回复信息（TODO: 待排查原因，猜测是因为需要等到上一次结果收到后再发起下一次请求）
        dispatch(
          send({
            cmd: "req",
            args: [
              `candle.${convertResolution(resolution)}.${name}`,
              to,
              countBack,
            ],
          })
        )

        dispatch(
          subscribeOnce({
            evtName: "req",
            cb: onMessage,
          })
        )
      } catch (e) {
        console.log("==> error", e)
        onErrorCallback(e || e.message || "获取历史K线数据失败")
      }
    }, 1000)

    // 数据的处理函数封装
    function onMessage(data) {
      // this.onLoadingHistoryData = false
      const diffTime = resolution === '1D' ? 57600 : 28800;
      const { ticks } = data
      const _ticks =
        ticks?.map(item => ({
          time: (item.id + diffTime) * 1000,
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
          volume: item.quote_volume || 0,
        })) ?? []
      onLoadedCallback(_ticks)
    }
    // 数据的回调函数封装
    function onLoadedCallback(data) {
      // console.log("===> wdfeqfeq", this.onLoadingHistoryData)
      // this.onLoadingHistoryData = false
      if (data && data.length) {
        onHistoryCallback(data, {
          noData: false,
        })
      } else {
        // message.warning("暂无更多历史数据")
        onHistoryCallback([], {
          noData: true,
        })
      }
    }
  }

  /**
   * 订阅当前商品对应周期的实时数据
   * @param {Object} symbolInfo 商品信息
   * @param {String} resolution 商品当前周期
   * @param {Function} onTick 更新图表最新Bar的回调函数
   * @param {String} listenerGuid 当前商品对应周期的监听编号（唯一）
   * @param {Function} onResetCacheNeededCallback K线数据需要被改变时调用此回调重新获取K线数据
   */
  subscribeBars = (
    symbolInfo,
    resolution,
    onTick,
    listenerGuid,
    onResetCacheNeededCallback
  ) => {
    // console.log(
    //   "[subscribeBars]: Method call with listenerGuid: ",
    //   listenerGuid
    // )
    subscribeOnStream(
      symbolInfo,
      resolution,
      onTick,
      listenerGuid,
      onResetCacheNeededCallback
    )
  }

  /**
   * 取消当前商品对应周期的实时数据订阅
   * @param {String} listenerGuid 当前商品对应周期的监听编号（唯一）
   */
  unsubscribeBars = listenerGuid => {
    // console.log(
    //   "[unsubscribeBars]: Method call with listenerGuid: ",
    //   listenerGuid
    // )
    unsubscribeFromStream(listenerGuid)
  }
}
