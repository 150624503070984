import { useState, useEffect } from "react"
import {
  Button,
  Dropdown,
  Menu,
  Table,
  Modal,
  notification,
  message,
} from "antd"
import styled from "styled-components"
import apis from "../../../services/apis"
import { post } from "../../../services/request"
import CardTabs from "../../common/CardTabs"
import { useDispatch, useSelector } from "react-redux"
import { selectSymbolByNames } from "../../../store/symbolSlice"
import config from "../../../config/config"
import { IconFont } from "../../common/IconFont"
import { isSafari, toDecimal } from "../../../utils/util"
import { Loading } from "../../common/Loading"
import {
  fetchOrders,
  selectAllOrders,
  updateTotalProfits,
} from "../../../store/orderSlice"
import { HistoryTable } from "./HistoryTable"
import { useGlobalModal } from "../../../utils/hooks"
import strings from "../../../constants/strings"
import { Empty } from "../../common/Empty"
import { FormattedMessage, useIntl } from "react-intl"
import { selectCustomItem } from "../../../store/customSlice"
import classNames from "classnames"
import { trackEvent } from "../../../utils/yeefx"

const { confirm } = Modal
const {
  cmdConfig,
  defaultString: DEFAULT_STRING,
  fetchStatus,
  locales,
} = config
const [, , FULFILLED] = fetchStatus
const { positionIds, buyIds } = cmdConfig
const [, , enus] = locales.ids

const Wrapper = styled.div`
  flex: 1;
  /* height: 100%; */
  width: 100%;
  margin-top: 8px;
  overflow: hidden;

  .ant-tabs-content-holder {
    height: 0;
  }
  .ant-table {
    background-color: transparent;
    .ant-table-thead {
      .ant-table-cell:nth-child(1),
      .ant-table-cell:nth-child(4),
      .ant-table-cell:nth-child(5) {
        padding-left: 8px;
      }
    }
    .ant-table-tbody {
      .op-cell-ticket {
        span:first-child {
          display: inline-block;
          min-width: 10em;
        }
      }
      .op-cell-price {
        span {
          width: 50%;
          display: inline-block;
        }
      }
      .op-cell-tp,
      .op-cell-sl {
        .op-btn-edit {
          width: initial;
          height: initial;
          padding: 0;
          .icon-edit {
            position: relative;
            top: -1px;
            width: 12px;
            height: 12px;
            margin-left: 12px;
            vertical-align: middle;
            fill: currentColor;
          }
        }
      }
      .op-cell-profit {
        font-weight: 700;
        & > .positive {
          color: ${props => props.theme.themeRed};
        }
        & > .negative {
          color: ${props => props.theme.themeGreen};
        }
      }
      .op-cell-operate {
        .op-btn-close {
          height: auto;
          padding: 0;
        }
        .icon-delete {
          width: 10px;
          height: 10px;
          fill: currentColor;
        }
      }
      td.ant-table-column-sort {
        background-color: ${props => props.theme.themeLight};
      }
    }
    .ant-table-body {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .op-dd-close,
  .op-btn-closeAllLimit {
    height: 22px;
    padding: 0 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font: inherit;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.theme} !important;
    &:hover {
      color: ${props => props.theme.white};
    }
    .icon-arrow {
      width: 12px;
      height: 12px;
      margin-left: 6px;
      fill: currentColor;
    }
  }
  .loading {
    height: 100%;
    margin: 0;
  }
  &.isSafari .ant-table-thead .ant-table-cell {
    height: 30px;
  }
`

const CloseDropdown = ({ overlay }) => (
  <Dropdown overlay={overlay}>
    <Button className="initial no-trigger op-dd-close">
      <FormattedMessage id="home.orderPane.market.ths.th10.btnText" />
      <IconFont className="icon-arrow" />
    </Button>
  </Dropdown>
)
/**
 * NOTE: 订单 Table 组件
 * @param {Number} type 0 -> 持仓单，1 -> 挂单
 */
// tableScrollHeight
const OrderTable = ({ data, type = 0 }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const showOrderEditModal = useGlobalModal(strings.modalKeyOrderEdit)
  const CLOSE_TXT = !type
    ? intl.formatMessage({ id: "home.orderPane.market.closeText" })
    : intl.formatMessage({ id: "home.orderPane.limit.closeText" })
  const names = (data?.length && data.map(item => item.symbol)) || []
  const isNeedCloseConfirm = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyCloseOrderConfirm)
  )
  const infos = useSelector(
    state => selectSymbolByNames(state, names),
    (prev, next) => {
      return JSON.stringify(prev) === JSON.stringify(next)
    }
  )
  const [listData, setListData] = useState(null)

  // 根据 MenuItem 的 key 获取需要平仓的订单编号数据
  const getTickets = key => {
    let tickets = []
    switch (Number(key)) {
      case 0:
        tickets = data.map(item => item.ticket)
        break
      case 1:
        data.map(item => Number(item.profit) > 0 && tickets.push(item.ticket))
        break
      case 2:
        data.map(item => Number(item.profit) < 0 && tickets.push(item.ticket))
        break
      default:
        break
    }
    return tickets
  }
  // const handleCloseTicketsFailed = data => {
  //   console.log("==> handleCloseTicketsFailed", data, MOCK_DATA)
  //   const closeSuccessfullyTickets = [], closeFailedTickets = []

  //   notification["error"]({
  //     message: intl.formatMessage(
  //       { id: "home.orderPane.market.message.error.closeFailed" },
  //       { closeText: CLOSE_TXT }
  //     ),
  //     description: error || "",
  //   })
  // }
  const handleCloseCB = async tickets => {
    const { error, data } = await post(apis.closeOrder, {
      ticket: tickets,
    })
    
    tickets = tickets.split(",")
    const closeSuccessfullyTickets = [],
      closeFailedTickets = []
    // const STATUS = ['平仓成功', '部分订单平仓失败', '平仓失败']
    let closeStatus
    data.map(item => {
      if (!Number(item.code)) {
        closeFailedTickets.push(item)
      } else {
        closeSuccessfullyTickets.push(item)
      }
      return item
    })

    const successTickets = closeSuccessfullyTickets.map(item => item.ticket)
    if (successTickets?.length) {
      trackEvent('订单', '平仓', { ticket: successTickets.toString() })
    }

    if (closeSuccessfullyTickets.length >= tickets.length) closeStatus = 0
    else if (closeSuccessfullyTickets.length >= 1) closeStatus = 1
    else closeStatus = 2
    // 处理结果提示弹出框内容
    switch (closeStatus) {
      case 0: {
        // 批量平仓全部成功
        notification["success"]({
          message: intl.formatMessage(
            { id: "home.orderPane.market.message.success.closeSuccess" },
            { closeText: CLOSE_TXT }
          ),
          description: intl.formatMessage(
            { id: "home.orderPane.market.message.success.closeSuccessDesc" },
            { tickets: tickets.join(","), closeText: CLOSE_TXT }
          ),
        })
        break
      }
      case 1:
      case 2: {
        // 部分 or 全部订单平仓失败
        const failedTickets = closeFailedTickets.map(t => t.ticket).join(",")
        const failedReason = closeFailedTickets.map(t => t.comment).join(",")
        notification["error"]({
          message: intl.formatMessage(
            { id: "home.orderPane.market.message.errorPartially.title" },
            { closeText: CLOSE_TXT }
          ),
          description: intl.formatMessage(
            { id: "home.orderPane.market.message.errorPartially.desc" },
            { failedTickets, failedReason }
          ),
        })
        break
      }
      default:
        break
    }
    closeStatus < 2 && dispatch(fetchOrders())
  }
  /**
   * 处理平仓
   * @param {Array} tickets 订单数组（单笔平仓数组长度为1）
   */
  const handleClose = tickets => {
    if (!tickets?.length) {
      message.warning(
        intl.formatMessage({
          id: "home.orderPane.market.message.error.noTickets",
        })
      )
      return
    }
    tickets = tickets.join(",")
    if (!isNeedCloseConfirm) {
      // NOTE: 用户自定义平仓配置
      handleCloseCB(tickets)
      return
    }
    confirm({
      title: intl.formatMessage(
        { id: "home.orderPane.market.confirm.title" },
        {
          closeText: CLOSE_TXT,
        }
      ),
      content: <>{tickets}</>,
      okText: intl.formatMessage({
        id: "home.orderPane.market.confirm.okText",
      }),
      cancelText: intl.formatMessage({
        id: "home.orderPane.market.confirm.cancelText",
      }),
      onOk: () => handleCloseCB(tickets),
    })
  }
  // 仅持仓使用
  const overlay = (
    <Menu onClick={val => handleClose(getTickets(val.key))}>
      <Menu.Item key={0}>
        <FormattedMessage id="home.orderPane.market.ths.th10.ddItem1" />
      </Menu.Item>
      <Menu.Item key={1}>
        <FormattedMessage id="home.orderPane.market.ths.th10.ddItem2" />
      </Menu.Item>
      <Menu.Item key={2}>
        <FormattedMessage id="home.orderPane.market.ths.th10.ddItem3" />
      </Menu.Item>
    </Menu>
  )
  const columns = [
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th1" />,
      dataIndex: "cnName",
      width: "15%",
      className: "op-cell-ticket",
      render: (val, record) => (
        <>
          <span>{intl.locale !== enus ? val : record.name}</span>
          <span>{record.ticket}</span>
        </>
      ),
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th2" />,
      dataIndex: "volume",
      align: "center",
      width: "6.94%",
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th3" />,
      dataIndex: "cmd",
      width: "9.46%",
      align: "center",
      render: val => cmdConfig.entities[val],
    },
    {
      title: (
        <FormattedMessage
          id={`home.orderPane.${!type ? "market" : "limit"}.ths.th4`}
        />
      ),
      dataIndex: "open_price",
      width: "13.12%",
      className: "op-cell-price",
      render: (val, record) => (
        <>
          <span>{val}</span>
          <span>{record.close_price}</span>
        </>
      ),
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th5" />,
      dataIndex: "open_time",
      width: "12.06%",
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th6" />,
      dataIndex: "tp",
      align: "center",
      width: "8.98%",
      className: "op-cell-tp",
      render: (val, record) => (
        <>
          <span>{val}</span>
          <Button
            className="initial no-trigger op-btn-edit"
            onClick={() =>
              showOrderEditModal({
                type: 0,
                data: { ...record, ...infos?.[record.symbol] },
              })
            }
          >
            <IconFont className="icon-edit" />
          </Button>
        </>
      ),
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th7" />,
      dataIndex: "sl",
      align: "center",
      width: "8.98%",
      className: "op-cell-sl",
      render: (val, record) => (
        <>
          <span>{val}</span>
          <Button
            className="initial no-trigger op-btn-edit"
            onClick={() =>
              showOrderEditModal({
                type: 1,
                data: { ...record, ...infos?.[record.symbol] },
              })
            }
          >
            <IconFont className="icon-edit" />
          </Button>
        </>
      ),
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th8" />,
      dataIndex: "storage",
      align: "center",
      width: "7.95%",
      render: val => <>{toDecimal(val, 2)}</>,
    },
    {
      title: <FormattedMessage id="home.orderPane.market.ths.th9" />,
      dataIndex: "profit",
      align: "center",
      className: "op-cell-profit",
      render: (val, record) => (
        <span className={Number(val) >= 0 ? "positive" : "negative"}>
          {!!val ? toDecimal(val, 2) : DEFAULT_STRING}
        </span>
      ),
    },
    {
      title: !type ? (
        <CloseDropdown overlay={overlay} />
      ) : (
        <Button
          className="initial no-trigger op-btn-closeAllLimit"
          onClick={() => handleClose(getTickets(0))}
        >
          <FormattedMessage id="home.orderPane.limit.ths.th10" />
        </Button>
      ),
      align: "center",
      className: "op-cell-operate",
      render: (_, record) => (
        <Button
          className="initial no-trigger op-btn-close"
          onClick={() => handleClose([record.ticket])}
        >
          <IconFont className="icon-delete" />
        </Button>
      ),
    },
  ]

  useEffect(() => {
    const handleData = data => {
      let totalProfit = 0
      const _data = data.map(item => {
        const { symbol, open_price, close_price, storage, volume, cmd } = item
        const symbolInfo = infos[symbol]
        if (!symbolInfo) return item
        const {
          ask, // ask 和 bid 是 ws的 quote 返回的数据
          bid,
          digits, // digits 是 ws 的 symbols 返回的数据
          size,
          trans_price_ask: transPriceAsk,
          cn_name: cnName = symbol,
        } = symbolInfo
        const currPrice = (() => {
          let price = close_price
          if (!positionIds.includes(cmd)) {
            // 挂单
            price = buyIds.includes(cmd) ? ask : bid
          } else {
            // 持仓单
            price = buyIds.includes(cmd) ? bid : ask
          }
          return price
        })()
        // (buyIds.includes(cmd) ? ask : bid) || close_price
        let profit = item.profit
        if (type === 0 && !!volume) {
          const isBuy = buyIds.includes(Number(cmd))
          const flag = (-1) ** (Number(isBuy) ^ 1)
          const closePrice = isBuy ? bid : ask
          if (!!transPriceAsk) {
            profit = (
              (closePrice - open_price) *
              volume *
              size *
              transPriceAsk *
              flag
            ).toFixed(2)
          }
        }
        totalProfit += Number(profit)
        return {
          ...item,
          open_price: toDecimal(open_price, digits),
          close_price: toDecimal(currPrice, digits),
          // tp: Number(tp) !== 0 ? tp : toDecimal(0, 2),
          // sl: Number(sl) !== 0 ? sl : toDecimal(0, 2),
          storage: toDecimal(storage, 2),
          name: symbol,
          cnName,
          profit,
        }
      })
      dispatch(
        updateTotalProfits({ idx: type, value: toDecimal(totalProfit, 2) })
      )
      return _data
    }
    setListData(handleData(data))
  }, [JSON.stringify(data), JSON.stringify(infos)])

  return (
    <>
      {data?.length ? (
        <Table
          className="scroll-auto"
          rowKey={record => record.ticket} // 必须！！
          dataSource={listData}
          // dataSource={handleData(data)}
          columns={columns}
          pagination={false}
          // scroll={!!tableScrollHeight && { y: tableScrollHeight }}
          scroll={{ y: "calc(100% - 30px)" }}
        />
      ) : (
        <Empty center={true} />
      )}
    </>
  )
}

const OrderPane = ({ onChangeTab }) => {
  const intl = useIntl()
  // const ref = useRef(null)
  // const [tableScrollHeight, setTableScrollHeight] = useState(0)
  const orderList = useSelector(selectAllOrders)
  const orderListStatus = useSelector(state => state.orders.status)

  // useEffect(() => {
  //   const getTableScrollHeight = () => {
  //     console.log(ref.current, ref.current.offsetHeight)
  //     setTableScrollHeight(ref.current.offsetHeight - 32 - 16)
  //   }
  //   getTableScrollHeight()
  //   window.addEventListener("resize", getTableScrollHeight)

  //   return () => {
  //     window.removeEventListener("resize", getTableScrollHeight)
  //   }
  // }, [])

  return (
    // ref={ref}
    <Wrapper className={classNames({ isSafari: isSafari() })}>
      <CardTabs
        onChange={onChangeTab}
        tabs={[
          {
            tab: intl.formatMessage({ id: "home.orderPane.tabs.tab1" }),
            key: "0",
            content: (
              <>
                {orderListStatus !== FULFILLED ? (
                  <Loading className="loading" />
                ) : (
                  <OrderTable
                    data={orderList.filter(item =>
                      positionIds.includes(item.cmd)
                    )}
                    // tableScrollHeight={tableScrollHeight - 30}
                    type={0}
                  />
                )}
              </>
            ),
          },
          {
            tab: intl.formatMessage({ id: "home.orderPane.tabs.tab2" }),
            key: "1",
            content: (
              <>
                {orderListStatus !== FULFILLED ? (
                  <Loading className="loading" />
                ) : (
                  <OrderTable
                    data={orderList.filter(
                      item => !positionIds.includes(item.cmd)
                    )}
                    // tableScrollHeight={tableScrollHeight - 30}
                    type={1}
                  />
                )}
              </>
            ),
          },
          {
            tab: intl.formatMessage({ id: "home.orderPane.tabs.tab3" }),
            key: "2",
            content: (
              <HistoryTable
              // tableScrollHeight={tableScrollHeight - 30}
              />
            ),
          },
        ]}
      />
    </Wrapper>
  )
}

export default OrderPane
